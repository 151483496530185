.user-card-full {
  user-select: text;
}

.success-tag {
  background-color: #388e3c;
}

.carousel-radius {
  border-radius: 0 0 65px 0px;
}

.countdown-text {
  display: flex;
  margin: 0.5rem 0;
  align-items: center;
  justify-content: flex-end;
}

.countdown-text > p {
  font-size: 0.8rem;
  font-weight: 500;
  color: #637381;
}

.countdown-text > button {
  background-color: #fff;
  border: none;
  color: #ff5630;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.label {
  /* font-family: "Roboto"; */
  font-weight: 400;
  font-size: 14px;
}

/* table#table1 {
    width: auto !important;
} */

.btn-custom-light {
  /* color: #fff;
    background-color: #adadea;
    border-color: #8282C8;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center; */
  color: #fff;
  width: 150px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.linear {
  border-radius: 45px 45px 0 0;
  background: linear-gradient(
    180deg,
    rgba(117, 69, 155, 1) 0%,
    rgba(178, 107, 234, 1) 100%
  ) !important;
}

.internship {
  font-size: 11px;
  font-weight: 500;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 50px;
  cursor: pointer;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #75449b;
  background: #f2d2ff;
}

.internship:hover {
  box-shadow: 0 3px 9px rgba(96, 94, 94, 0.17);
}

.btn-xs {
  padding: 0.25rem 0.4rem;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.custom-container {
  width: 90%;
}

.links {
  /* text-decoration: none; */
  font-size: 1.125rem;
  font-weight: 600;
  color: #034a59;
  cursor: pointer;
  padding: 0 17px;
  letter-spacing: 0.5px;
  border-radius: 4px;
  line-height: 37px;
  margin-top: 10px;
  margin-bottom: 12px;
  background: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.custom-search-btn {
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 30px;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: white;
  color: rgb(0, 0, 0);
  /* border: none; */
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border-width: 0.5px;
  border-color: #a373c7;
}

.custom-search-btn:hover {
  background-color: #a373c7;
  text-decoration: none;
  color: #fff;
  border-color: #a373c7;
}

.custom-btn-header {
  font-size: 14px;
  padding: 10px 30px;
  border-radius: 30px;
  letter-spacing: 1px;
  font-weight: 400;
  background-color: white;
  color: rgb(0, 0, 0);
  border: solid;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  border-width: 0.5px;
  border-color: #a373c7;
}

.custom-btn-header:hover {
  background-color: #a373c7;
  text-decoration: none;
  color: #fff !important;
  border-color: #a373c7;
}

.theme-header {
  width: 100%;
  display: block !important;
}

.navbar {
  background-color: white;
  top: 0px !important;
}

.search-section {
  display: grid;
  grid-template-columns: 35% 35%;
  justify-content: center;
}

.navbar-brand {
  padding: 0px !important;
}

.viewMore {
  text-align: center;
  margin-top: 50px;
}

.groupHeading {
  color: var(--wp--global-text-grey-text-primary) !important;
  font-weight: bolder !important;
  padding: 5px 15px !important;
  pointer-events: none !important;
  border-top: 2px solid lightgray;
}

.custom-content {
  padding-bottom: 10%;
  padding-top: 6%;
}

.sticky-div {
  position: absolute;
  top: 155px;
  left: 20px;
  right: 20px;
}

.fix-sticky-div .sticky-div {
  position: fixed;
  top: 10px;
}

.is-sticky {
  position: fixed;
  left: 0px;
  padding: 33px;
  width: 100%;
  z-index: 1100;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: white !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 25px;
  padding-bottom: 25px;
}

.searchWrapper {
  border: 0 !important;
  border-radius: 4px;
  min-height: 22px;
  padding: 5px;
  position: relative;
}

.float-search {
  top: 90%;
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
}

.float-search-staffs {
  top: 90%;
  width: 50%;
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
}

.home-float-search-staffs {
  top: 80%;
  width: 100%;
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
}

.login-head {
  display: flex;
  justify-content: center;
  color: #fff;
}

.img-res {
  max-width: 75%;
}

.top-padding {
  top: 160px;
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  /* margin-left: 15px; */
}

.did-floating-label-content {
  position: relative;
}

.did-floating-label {
  color: var(--wp--preset--color--primary-blue);
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 8px;
  padding: 0 5px;
  background: #fff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.did-floating-labe-astrisk::after {
  content: "*";
  color: red;
}

.did-floating-label-custom {
  /* font-size: 13px; */
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 11px;
  padding: 0 5px;
  background: #faf1ff;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

::-webkit-file-upload-button {
  display: none;
}

.file-placeholder::before {
  content: "Click Here To Upload";
}

.did-floating-input {
  font-size: 12px;
  /* display: block; */
  width: 100%;
  height: 38px;
  padding: 0 20px;
  line-height: 35px;
  background: #fff;
  color: #323840;
  border: 1px solid var(--wp--global-text-grey-text-primary);
  border-radius: 20px;
  box-sizing: border-box;
}

.did-floating-input:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
  /* opacity: 0.5;
    filter: opacity(0.5); */
  border-color: gray;
}

.did-floating-select {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: #faf1ff;
  color: #323840;
  border: 1px solid var(--wp--global-text-grey-text-primary);
  border-radius: 5px;
  box-sizing: border-box;
}

.did-floating-input:focus,
.did-floating-select:focus {
  outline: none;
  border: 1px solid blue;
}

.did-floating-input:focus ~ .did-floating-label,
.did-floating-select:focus ~ .did-floating-label-custom {
  top: -8px;
  font-size: 13px;
}

.did-floating-input:not(:placeholder-shown) ~ .did-floating-label {
  top: -8px;
  font-size: 14px;
  font-weight: 500;
}

/* Float Dropdown */
.did-floating-dropdown {
  font-size: 12px;
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 20px;
  background: #fff;
  color: #323840;
  border: 1px solid var(--wp--global-text-grey-text-primary);
  border-radius: 20px;
  box-sizing: border-box;
}

.border-color-common {
  border: 1px solid var(--wp--global-text-grey-text-primary);
}

/* .did-floating-dropdown:disabled{
    background: rgb(232, 231, 231);
}
 */

.did-floating-dropdown:focus {
  outline: none;
}

select.did-floating-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select.did-floating-dropdown::-ms-expand {
  display: none;
}

.did-floating-label-top {
  top: -8px !important;
  font-size: 13px;
  font-size: 10px;
  font-weight: 500;
  color: #232323;
}

.did-floating-label-down {
  top: 11px !important;
  font-size: 13px;
}

/* Select Box */

.did-floating-select:not([value=""]):valid ~ .did-floating-label-custom {
  top: -8px;
  font-size: 13px;
}

.social-button {
  background-position: 25px 0px;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  width: 100%;
  border-radius: 3px;
  margin: 10px 7px auto;
  outline: rgb(255, 255, 255) none 0px;
  transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#facebook-connect {
  display: flex;
  justify-content: center;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(60, 90, 154);
  border-radius: 50px;
  align-items: center;
}

#facebook-connect:hover {
  border-color: rgb(60, 90, 154);
  background: rgb(60, 90, 154);
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-out;
}

#facebook-connect span {
  box-sizing: border-box;
  color: rgb(60, 90, 154);
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: 0px none rgb(255, 255, 255);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#facebook-connect:hover span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#google-connect {
  display: flex;
  justify-content: center;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(220, 74, 61);
  align-items: center;
}

#google-connect:hover {
  border-color: rgb(220, 74, 61);
  background: rgb(220, 74, 61);
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-out;
}

#google-connect span {
  box-sizing: border-box;
  color: rgb(220, 74, 61);
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  border: 0px none rgb(220, 74, 61);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#google-connect:hover span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#linkedin-connect {
  display: flex;
  justify-content: center;
  border-radius: 50px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 119, 181);
  align-items: center;
}

#linkedin-connect:hover {
  border-color: rgb(0, 119, 181);
  background: rgb(0, 119, 181);
  -webkit-transition: all 0.8s ease-out;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease-out;
}

#linkedin-connect span {
  box-sizing: border-box;
  color: rgb(0, 119, 181);
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  border: 0px none rgb(0, 119, 181);
  outline: rgb(255, 255, 255) none 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#linkedin-connect:hover span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.grid__item {
  align-items: center;
  min-height: 300px;
  font-size: 5em;
  color: inherit;
  text-decoration: none;
  background: #fff;
  margin: 10px;
}

.grid__item:hover {
  color: #782db6;
}

.grid__item:focus + .grid__description {
  max-height: 200px;
  transition: max-height 0.4s ease-in;
}

.grid__description {
  max-height: 0;
  overflow: hidden;
  background: #fff;
  transition: max-height 0.15s ease-in;
  display: inline-block;
  position: relative;
}

.box {
  width: 100%;
  margin: 15px auto;
  background-color: #8282c8;
  font-weight: 900;
  color: #fff;
  font-family: arial;
  position: relative;
  border-radius: 10px;
}

.sb1:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid #00bfb600;
  border-right: 10px solid transparent;
  border-top: 10px solid #00bfb600;
  border-bottom: 10px solid #8282c8;
  right: 0;
  left: 13px;
  top: -19px;
}

.grid__description div {
  padding: 2em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

@media screen and (min-width: 30em) {
  .grid {
    display: grid;
    grid-template-areas:
      "item item item"
      "desc desc  desc";
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid__description {
    grid-area: desc;
  }
}

@media screen and (min-width: 30em) {
  .grid-job {
    display: grid;
    grid-template-areas:
      "item  item"
      "desc   desc";
    grid-template-columns: 1fr 1fr;
  }

  .grid__description {
    grid-area: desc;
  }
}

.svg-background {
  width: 90%;
  height: 100%;
  border-radius: 13px 0 0 12px;
  top: 0;
  left: 0;
  background-color: #8282c8;
  -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  clip-path: polygon(0 0, 14% 0, 86% 100%, 0% 100%);
}

.svg-background2 {
  width: 100%;
  height: 100%;
  border-radius: 13px 0 0 12px;
  top: 0;
  left: 0;
  background-color: red;
  -webkit-clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  clip-path: polygon(0 0, 14% 0, 48% 100%, 0% 100%);
  z-index: -9;
}

.profile-img {
  position: absolute;
  width: 100px;
  /* height: 100px; */
  border-radius: 50%;
  margin-left: 50px;
}

.circle {
  position: absolute;
  width: 162px;
  height: 161px;
  left: 0;
  top: 0;
  background-color: #eceff1;
  border-radius: 50%;
  margin-top: 50.5px;
  margin-left: 35px;
}

.text-container {
  text-align: start;
  left: 0;
  width: 100%;
}

.desc-text {
  font-size: 10px;
  margin-top: 14px;
}

.container-profile {
  position: relative;
  background-color: #eceff1;
  height: 150px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-shadow: 0 10px 20px -10px rgb(226 226 226 / 75%);
}

.tag {
  top: 14px;
  display: block;
  width: 100px;
  height: 25px;
  color: rgb(226, 88, 34);
  transform: rotate(45deg);
  position: absolute;
  text-align: center;
  line-height: 24px;
  right: -24px;
  font-family: arial;
  font-size: 15px;
  background: linear-gradient(
    254deg,
    rgba(130, 130, 200, 1) 0%,
    rgba(187, 189, 244, 1) 100%
  );
}

/* CARD DESIGN STAFF SEARCH START*/
.front-side {
  height: 300px;
  display: grid;
  grid-template-columns: 2% 96% 2%;
  grid-template-rows: 4% 92% 4%;
  position: relative;
  margin: 20px auto;
  overflow: hidden;
  background-color: #ffffff;
}

.front-side-sekelton {
  margin: 5px;
  overflow: hidden;
  background-color: #ffffff;
}

.title-text {
  margin-bottom: 0px;
  color: #263238;
  font-size: 25px;
  font-weight: 600;
  margin-top: 5px;
}

.info-text {
  margin-top: 10px;
  /* font-size: 15px; */
  font: message-box;
}

.info-address {
  margin-top: 10px;
  font-size: 12px;
}

.web {
  font-size: 15px;
  font-weight: bold;
}

.color-grid {
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-template-rows: repeat(8, 50px);
  grid-gap: 8px;
  width: 150px;
  height: 400px;
  transform: rotate(-45deg);
}

.black {
  background-color: #ffffff;
  grid-column: 2 / span 2;
  grid-row: 1 / span 3;
}

.red1 {
  background-color: #ffffff;
  grid-row: 2 / span 5;
}

.red2 {
  background-color: #ffffff;
  grid-column: 2 / span 2;
  grid-row: 4/7;
}

.green {
  background-color: #ffffff;
  grid-column: 2/4;
  grid-row: 7/9;
}

.info-grid {
  grid-column: 2/3;
  grid-row: 2/3;
  z-index: 2;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 255, 255, 0.363); */
  background: linear-gradient(
    187deg,
    rgba(251, 251, 251, 1) 0%,
    rgba(176, 176, 245, 1) 100%
  );
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.432);
  border-radius: 6px;
  font-size: 0.7rem;
  /*12px;*/
  display: grid;
  grid-template-columns: 15px repeat(3, 1fr) 15px;
  grid-template-rows: repeat(3, 1fr);
  line-height: 1px;
}

.info-grid .name {
  grid-column: 2 / span 2;
  grid-row: 1 / span 2;
  font-size: 1.2em;
  letter-spacing: 0.1rem;
  margin-top: 8px;
}

.info-grid .signup {
  grid-column: 2 / span 2;
  display: flex;
  grid-row: 2 / span 1;
  font-size: 1.2em;
  letter-spacing: 0.1rem;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.info-grid .name h5 {
  font-weight: unset;
}

.info-grid .addr {
  grid-column: 2 / span 2;
  grid-row: 3;
}

.info-grid .vaccinated {
  grid-column: 4 / span 1;
  grid-row: 1;
  text-align: end;
  margin: 0px;
  display: flex;
  right: 23px;
  position: absolute;
}

.info-grid .emailId {
  grid-column: 3 / span 2;
  grid-row: 3;
  text-align: end;
  font-size: 1.1em;
  margin-top: 50px;
}

.view-more {
  grid-column: 2 / span 2;
  grid-row: 4;
  text-align: end;
  width: 100px;
  font-size: 1.3em;
  cursor: pointer;
}

.vaccinated-img {
  height: 60px;
  width: 60px;
}

/* CARD DESIGN STAFF SEARCH END*/

.list {
  color: #263238;
  background-color: transparent;
  height: 300px;
  perspective: 1000px;
}

.list:hover {
  color: #782db6;
}

/* FLIP CARD */
.flip-card-inner {
  cursor: pointer;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.list:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  /* Safari */
  backface-visibility: hidden;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}

/* END */

.signup-link {
  text-decoration: underline !important;
  cursor: pointer !important;
}

.admin-link:hover {
  text-decoration: underline !important;
  cursor: pointer !important;
  /* text-decoration-color: red !important; */
}

.pointer {
  cursor: pointer !important;
}

.otpContainer {
  display: flex;
  justify-content: center;
  margin: 5% auto;
}

.otpInput {
  margin: 0 1rem;
  font-size: 2rem;
  text-align: center;
}

#watermark {
  position: absolute;
  right: 0;
  left: 0;
  opacity: 1;
  z-index: 99;
  color: rgb(255, 0, 0);
  font-size: 20px;
  text-align: center;
}

.social-btn:hover {
  color: #9b5dcc;
  background-color: #ffffff;
  border-color: #ffffff;
}

[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 999 !important;
}

.swal-button--confirmChange {
  background-color: #f44336 !important;
}

.swal-button--anotherButton {
  background-color: #4caf50 !important;
  /* Blue color for the "ANOTHER BUTTON" button */
}

.swal-button {
  padding: 7px 19px;
  border-radius: 8px;
  color: #fff;
  background-color: #75449b;
  font-size: 12px;
  border: 1px solid #75449b;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal-button--cancel:not([disabled]):hover {
  background-color: #8282c8;
}

.swal-button:not([disabled]):hover {
  background-color: #8282c8;
}

.swal-title {
  font-size: 20px;
}

.rate {
  float: left;
  height: 10px;
  /* padding: 0 10px; */
}

.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked) > label:before {
  content: "★ ";
}

.rate > input:checked ~ label {
  color: #ffc700;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}

.rate-view {
  float: left;
  height: 10px;
  /* padding: 0 10px; */
}

.rate-view:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate-view:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate-view:not(:checked) > label:before {
  content: "★ ";
}

.rate-view > input:checked ~ label {
  color: #ffc700;
}

.badge-tag {
  position: absolute;
  right: -4px;
  top: 3px;
  border: solid 2px;
  border-radius: 50%;
  padding: 0.35em 0.65em;
  font-size: 0.45em;
  font-weight: 700;
  background: red;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.badge-tag-extention {
  border: solid 2px;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: 8px;
  padding: 0.2em 0.65em;
  font-size: 0.65em;
  font-weight: 700;
  background: red;
  color: #fff;
  text-align: center;
}

.badge-tag-admin {
  position: absolute;
  right: 9px;
  top: 19px;
  border: solid 2px;
  border-radius: 92%;
  padding: 0px 5px 0px 5px;
  font-size: 0.56em;
  font-weight: 700;
  background: red;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

select option:hover {
  box-shadow: 0 0 10px 100px green inset;
  color: white;
}

.indexedStep {
  color: white;
  width: 100px;
  height: 50px;
  /* font-size: 20px; */
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.indexedStep.accomplished {
  background-color: #75449b;
}

.indexedStepOnboarding {
  color: white;
  width: 150px;
  height: 70px;
  font-weight: bold;
  background-color: #f2f2f2;
  transition: background-color 0.3s ease;
  /* font-size: 20px; */
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.indexedStepOnboarding.accomplishedOnboarding {
  background-color: #75449b;
}

.bookmark-filled {
  z-index: 9999;
  position: relative;
  height: 30px;
  width: 19px;
  padding: 0px;
  transform: rotate(0deg) skew(0deg);
  border-left: 12px solid #75449b;
  border-right: 12px solid #75449b;
  border-bottom: 11px solid transparent;
}

.bookmark {
  z-index: 9999;
  color: #75449b;
  height: 30px;
  width: 24px;
  position: relative;
  border-radius: 0 0 1px 1px;
  border-top: solid 0px currentColor;
  border-left: solid 1px currentColor;
  border-right: solid 1px currentColor;
}

.bookmark:before {
  content: "";
  position: absolute;
  top: 21px;
  left: 3.5px;
  width: 16px;
  height: 16px;
  z-index: 0;
  border-top: solid 1px currentColor;
  border-left: solid 1px currentColor;
  transform: rotate(46deg);
  transition: transform 0.2s;
}

.bookmark:hover {
  top: 7px;
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  transition: transform 250ms;
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.bookmark-filled:hover {
  top: 7px;
  -ms-transform: scale(1.5);
  /* IE 9 */
  -webkit-transform: scale(1.5);
  /* Safari 3-8 */
  transform: scale(1.5);
  transition: transform 250ms;
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.ck-editor__editable_inline {
  min-height: 200px;
}

.ck.ck-editor {
  border: 1px solid var(--wp--global-text-grey-text-primary);
  border-radius: 10px;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-radius: 0px 0px 10px 10px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-radius: 10px 10px 10px 10px;
}

.drawer-icon {
  height: 20.25px;
  width: 18px;
}

.action-btn {
  display: flex;
  justify-content: center;
  margin: 5px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  /* background: transparent; */
  /* bottom: 0; */
  /* color: transparent; */
  /* cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto; */
}

.more-menu {
  display: flex;
  width: 100%;
  clear: both;
  background-color: transparent;
}

.dropdown .dropdown-menu {
  padding: 5px;
}

.dropdown-multi {
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 1px 1px 5px 1px #d9d1d1;
  padding: 4px;
  font-family: "Rubik";
}

.dropdown-multi .chip {
  background-color: var(--wp--global-text-grey-text-primary);
  /* Set the desired background color for the chip */
  margin: 0px;
  color: white;
  /* Set the desired text color for the chip */
}

.search-datepicker {
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 1px 1px 5px 1px #d9d1d1;
  padding: 10px;
  background: #f6e8ff;
  font-family: "Rubik";
}

.field-icon {
  float: right;
  margin-left: -25px;
  right: 10px;
  margin-top: -29px;
  position: relative;
  z-index: 2;
  color: var(--wp--global-text-grey-text-primary);
}
