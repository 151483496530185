.transfer-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.list-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.available-items,
.selected-items {
  width: 43%;
  min-height: 250px;
  max-height: 250px;
  /* 
  overflow-y: auto; */
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: #eef1f8;
}

.available-items h3,
.selected-items h3 {
  font-size: 18px;
  line-height: 24px;
  color: var(--wp--preset--color--primary-blue);
  font-weight: 500;
}

.available-items input,
.selected-items input {
  border: 1px solid var(--wp--preset--color--primary-blue);
  border-radius: 8px;
}

.transfer-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.transfer-buttons button {
  margin: 5px;
  padding: 10px;
  border: none;
  background-color: var(--wp--preset--color--primary-blue);
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
}

.transfer-buttons button:disabled {
  background-color: transparent;
  cursor: not-allowed;
  color: var(--wp--preset--color--primary-blue);
  border: 2px solid var(--wp--preset--color--primary-blue);
}

.list-item {
  display: flex;
  align-items: center;
  margin: 7px 0;
}

.search-bar {
  margin-bottom: 10px;
}

.item-name {
  cursor: pointer;
  display: inline-block;
  margin-left: 8px; /* Adjust as needed */
  font-size: 14px;
  line-height: 16px;
  color: #525252;
  font-weight: 500;
}

.available-list-scroll {
  max-height: 156px;
  overflow-y: scroll;
}
.available-items .search-bar,
.selected-items .search-bar {
  width: 100%;
  padding: 2px 4px;
}
.available-items .search-bar:focus-visible,
.selected-items .search-bar:focus-visible {
  outline: none;
}
.modal-body {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* width */
.modal-body::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.modal-body::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.modal-body::-webkit-scrollbar-thumb {
  background: var(--wp--preset--color--primary-blue);
}

/* Handle on hover */
.modal-body::-webkit-scrollbar-thumb:hover {
  background: var(--wp--preset--color--primary-blue);
}

@media (max-width: 1200px) {
  .available-items,
  .selected-items {
    max-height: 300px;
  }
}
@media (max-width: 768px) {
  .transfer-buttons button {
    padding: 5px;
  }
  .modal-header,
  .modal-body {
    padding: 0.6rem;
  }
  .available-items h3,
  .selected-items h3 {
    font-size: 16px;
  }
  .transfer-buttons {
    margin: 0 4px;
  }
  .list-item {
    font-size: 12px;
    line-height: 15px;
    align-items: start;
    gap: 2px;
  }
}
@media (max-width: 540px) {
  .list-container {
    flex-wrap: wrap;
  }
  .available-items,
  .selected-items {
    width: 100%;
  }
  .transfer-buttons {
    width: 100%;
    flex-direction: row;
  }
  .transfer-buttons {
    margin: 4px 4px;
  }
  .available-items .search-bar,
  .selected-items .search-bar {
    width: 100%;
  }
  .right-side .login-heading {
    margin-bottom: 0.4rem !important;
  }
  .right-side .login-heading {
    max-height: 150px;
  }
  .right-side .common-btn {
    margin: 4px 0;
  }
}
