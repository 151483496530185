.copy-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fafafa;
    border-radius: 3px;
    font-family: sans-serif;
    font-size: 16px;
}

.copy-icon {
    cursor: pointer;
    position: relative;
}

.copy-icon i {
    margin-right: 5px;
}

.copy-message {
    background-color: #111111;
    color: #ffffff;
    position: absolute;
    top: -25px;
    right: -10px;
    font-weight: 600;
    font-size: 12px;
    padding: 4px 5px;
    border-radius: 3px;
    animation: fadeInOut 1s ease;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.arrow-down {
    position: absolute;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #111111;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}
