.ehr-details-form {
  padding: 20px;
}

.login-heading {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
}

.scope-item {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}

.remove-scope-btn {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 2.5rem;
  position: absolute;
  top: -36px;
  right: -19px;
  cursor: pointer;
  z-index: 10;
}

.add-scope-btn {
  display: inline-block;
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
